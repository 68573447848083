/*
	http://meyerweb.com/eric/tools/css/reset/
	v2.0 | 20110126
	License: none (public domain)
*/
@import url(//fonts.googleapis.com/css?family=Codystar:300,400|Anaheim|Text+Me+One);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
q:before,
blockquote:after,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
@font-face {
  font-family: 'Social';
  src: url('../fonts/socialnetworkingicons-regul-webfont.eot');
  src: url('../fonts/socialnetworkingicons-regul-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/socialnetworkingicons-regul-webfont.woff') format('woff'), url('../fonts/socialnetworkingicons-regul-webfont.ttf') format('truetype'), url('../fonts/socialnetworkingicons-regul-webfont.svg#social_networking_iconsRg') format('svg');
  font-weight: normal;
  font-style: normal;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  background-color: #000000;
  font-family: 'Text Me One', Helvetica, sans-serif;
  color: #ffffff;
}
a {
  color: #ffffff;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: dotted 1px;
}
a:hover {
  border-bottom: none;
}
h1 {
  font-family: 'Codystar', Helvetica, sans-serif;
  font-size: 2em;
}
ul.socials {
  margin: 0 auto;
  display: table;
  width: 100%;
}
ul.socials LI {
  display: inline-block;
  width: 32px;
  margin: 0 0.2em;
}
ul.socials LI A {
  display: block;
  width: 100%;
  height: 32px;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
  border: none;
  background: no-repeat center;
}
ul.socials LI.fb A {
  background-image: url("../../images/icon_fb.png");
}
ul.socials LI.yt A {
  background-image: url("../../images/icon_yt.png");
}
ul.socials LI.ig A {
  background-image: url("../../images/icon_ig.png");
}
.video {
  padding: 0;
  font-size: 26px;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background: url("../img/video-placeholder.png") center;
  min-height: 100vh;
}
.video ul {
  position: absolute;
  bottom: 130px;
  z-index: 1;
}
.video .logo {
  display: block;
  position: absolute;
  bottom: 30px;
  background: url("../img/logo-simonealgeri.png") center no-repeat;
  background-size: contain;
  width: 100%;
  height: 70px;
}
.video video {
  display: none;
  width: 100%;
}
@media (min-width: 1140px) {
  .video {
    background-image: none;
    max-width: 100%;
    min-height: 100%;
  }
  .video ul {
    bottom: 200px;
  }
  .video .logo {
    display: none;
  }
  .video video {
    display: block;
  }
}
.content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 1em;
  text-align: center;
  line-height: 2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
}
.content .desc {
  align-self: center;
  max-width: 600px;
}
.content .desc p + p {
  margin-top: 1em;
}
