@import url(//fonts.googleapis.com/css?family=Codystar:300,400|Anaheim|Text+Me+One);

@font-face {
    font-family: 'Social';
    src: url('../fonts/socialnetworkingicons-regul-webfont.eot');
    src: url('../fonts/socialnetworkingicons-regul-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/socialnetworkingicons-regul-webfont.woff') format('woff'),
         url('../fonts/socialnetworkingicons-regul-webfont.ttf') format('truetype'),
         url('../fonts/socialnetworkingicons-regul-webfont.svg#social_networking_iconsRg') format('svg');
    font-weight: normal;
    font-style: normal;
}