html, body {
  width: 100%;
  height: 100%;
}

body {
  background-color: @color-bg;
  font-family: @font-text;
  color: @color-text;
}
a {
  color: @color-text;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: dotted 1px;
  &:hover {
    border-bottom: none;
  }
}

h1 {
  font-family: @font-title;
  font-size: 2em;
}


ul.socials {
  margin:0 auto;
  display:table;
  width: 100%;
  LI {
    display: inline-block;
    width: 32px;
    margin:0 0.2em;
    A {
      display: block;
      width: 100%;
      height: 32px;
      text-indent: 200%;
      white-space: nowrap;
      overflow: hidden;
      border: none;
      background: no-repeat center;
    }
    &.fb A {
      background-image:url("@{url-images}icon_fb.png");
    }

    &.yt A {
      background-image:url("@{url-images}icon_yt.png");
    }

    &.ig A {
      background-image:url("@{url-images}icon_ig.png");
    }
  }
}

.video {
  padding: 0;
  font-size: 26px;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background: url("@{url-img}video-placeholder.png") center;
  min-height: 100vh;
  ul {
    position: absolute;
    bottom: 130px;
    z-index: 1;
  }
  .logo {
    display: block;
    position: absolute;
    bottom: 30px;
    background: url("@{url-img}logo-simonealgeri.png") center no-repeat;
    background-size: contain;
    width: 100%;
    height: 70px;
  }
  video {
    display: none;
    width: 100%;
  }
  @media (min-width: @screen-lg) {
    background-image: none;
    max-width: 100%;
    min-height: 100%;
    ul {
      bottom: 200px;
    }
    .logo {
      display: none;
    }
    video {
      display: block;
    }
  }
}


.content {
  margin: 0 auto;
  max-width: @max-width-container;
  padding: 1em;
  text-align: center;
  line-height: 2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  .desc {
    align-self: center;
    max-width: 600px;
    p + p {
      margin-top: 1em;
    }
  }
}